/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Parallax } from 'react-scroll-parallax';
import { StaticImage } from 'gatsby-plugin-image';
import { Button, Community, Layout, SEO } from '@/components';

export const Head = ({ location }) => {
  const title = 'Features';
  const description =
    'Fully customizable tools that will help you make the most of your time and focus on what really matters';
  return <SEO title={title} description={description} location={location} />;
};

const FeaturesPage = () => (
  <Layout logo dark>
    <div className="pattern grid grid-cols-2 py-[5.25rem] px-4 lg:px-[8.75rem] space-y-16">
      <div className="lg:col-span-1 lg:order-1 lg:mt-0 flex items-center justify-center order-2 col-span-2 mt-16">
        <Parallax translateY={[-10, 30]} speed="fast">
          <StaticImage
            className="lg:mr-8 lg:mb-0 lg:inline block max-w-2xl mx-auto mb-16"
            src="../images/header-features.png"
            alt=""
            formats={['auto', 'webp', 'avif']}
            placeholder="blurred"
            layout="constrained"
          />
        </Parallax>
      </div>
      <div className="lg:col-span-1 lg:mr-24 lg:ml-16 lg:order-2 flex-row items-start justify-center order-1 col-span-2 space-y-8">
        <h2 className="font-heading lg:text-4xl xl:text-6xl text-3xl font-bold leading-normal text-left">
          All The Tools You Need In One Place!
        </h2>
        <p className="mb-8 font-kanit text-3xl font-extralight text-[#969696] lg:text-[2.5rem]">
          Fully customizable tools that will help you make the most of your time and focus on what really matters.
        </p>
        <p className="mb-8 font-kanit text-3xl font-extralight text-[#969696] lg:text-[2.5rem]">
          Frustration-free management is now a few clicks away!
        </p>
        <Button to="/coworking-spaces" className="!block mx-auto lg:mx-0 lg:inline">
          View more
        </Button>
      </div>
    </div>

    <div className="grid grid-cols-1 py-[5.25rem] px-4 lg:px-[8.75rem]">
      <div className="items-center col-span-1">
        <h2 className="font-heading lg:text-6xl mb-8 text-4xl font-bold leading-normal text-center">Our features</h2>
        <p className="mb-8 text-center font-content text-[1.625rem] font-bold lg:mx-32">
          All-in-one Dashboard! Start your day with a dynamic and thorough overview of your space. The dashboard allows
          you to track and visualize all your important data instantly.
        </p>
      </div>
    </div>
    <div className="grid grid-cols-4 px-4 pt-[5.25rem] lg:px-[13.75rem]">
      <div className="lg:col-span-1 lg:col-start-3 flex items-center justify-center col-span-2">
        <h4 className="text-center font-heading text-[2rem] font-bold">Coworking spaces</h4>
      </div>
      <div className="lg:col-span-1 flex flex-col items-center justify-center col-span-2">
        <h4 className="text-center font-heading text-[2rem] font-bold">Incubators</h4>
      </div>
    </div>
    <div className="mx-4 grid grid-cols-4 space-y-10 border-b-2 border-[#707070]  pb-[5.25rem] lg:mx-[13.75rem]">
      <div className="col-span-4 mt-8 font-heading text-[1.875rem] text-secondary">Internal Management</div>
      <div className="col-span-2 col-start-1  font-heading text-[1.875rem]">Space user management</div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="col-span-2 col-start-1  font-heading text-[1.875rem]">Events management</div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="col-span-2 col-start-1  font-heading text-[1.875rem]">Plans / memberships management</div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="col-span-2 col-start-1  font-heading text-[1.875rem]">Lost & found management</div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="col-span-2 col-start-1  font-heading text-[1.875rem]">Claims management</div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="col-span-2 col-start-1  font-heading text-[1.875rem]">Rooms booking management</div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="col-span-2 col-start-1  font-heading text-[1.875rem]">Front desk</div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="col-span-2 col-start-1  font-heading text-[1.875rem]">Surveys</div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
    </div>
    <div className="mx-4 grid grid-cols-4 space-y-10 border-b-2 border-[#707070]  pb-[5.25rem] lg:mx-[13.75rem]">
      <div className="col-span-4 mt-8 font-heading text-[1.875rem] text-secondary"> Financial Management</div>
      <div className="col-span-2 col-start-1  font-heading text-[1.875rem]">Sales</div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-grey.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="col-span-2 col-start-1  font-heading text-[1.875rem]">Purchases</div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-grey.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="col-span-2 col-start-1  font-heading text-[1.875rem]">Assets</div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-grey.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
    </div>
    <div className="mx-4 grid grid-cols-4 space-y-10 border-b-2 border-[#707070]  pb-[5.25rem] lg:mx-[13.75rem]">
      <div className="col-span-4 mt-8 font-heading text-[1.875rem] text-secondary">Incubation Management</div>
      <div className="col-span-2 col-start-1  font-heading text-[1.875rem]">Submissions</div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-grey.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="col-span-2 col-start-1  font-heading text-[1.875rem]"> One-on-one’s</div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-grey.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="col-span-2 col-start-1  font-heading text-[1.875rem]">Assignments</div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-grey.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
    </div>
    <div className="mx-4 grid grid-cols-4 space-y-10 border-b-2 border-[#707070]  pb-[5.25rem] lg:mx-[13.75rem]">
      <div className="col-span-4 mt-8 font-heading text-[1.875rem] text-secondary">Community Management</div>
      <div className="col-span-2 col-start-1  font-heading text-[1.875rem]">Circles & Feed</div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="col-span-2 col-start-1  font-heading text-[1.875rem]">Messaging</div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="col-span-2 col-start-1  font-heading text-[1.875rem]">People & startups</div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
      <div className="flex flex-row justify-center col-span-1">
        <StaticImage
          src="../images/mark-green.png"
          alt=""
          formats={['auto', 'webp', 'avif']}
          placeholder="blurred"
          layout="fixed"
          width={32}
        />
      </div>
    </div>
    <Community />
  </Layout>
);

export default FeaturesPage;
